<template>
    <div class="modal fade" id="viewUser">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">#TE-{{user.id.substr(0,10)}}</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card contact-bx item-content">
                        <div class="card-header border-0">

                        </div>
                        <div class="card-body user-profile">
                            <div class="image-bx">
                                <span class="icon-placeholder bgl-success rounded-circle text-success" v-if="user.data.name">
                                    {{user.data.name.substr(0,2)}}
                                </span>
                            </div>
                            <div class="media-body user-meta-info">
                                <h6 class="fs-20 font-w500 my-1">
                                    <a href="javascript:void(0)" class="text-black user-name" :data-name="user.data.email">
                                        {{user.data.email}}
                                    </a>
                                </h6>
                                <p class="fs-14 mb-3 user-work" :data-occupation="user.data.country">
                                    {{user.data.country}}
                                </p>
                            </div>

                            <div class="container-fluid px-0">
                                <div class="row text-left">
                                    <div class="col-12">
                                        <h6>Name</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{user.data.name}}</div>

                                    <div class="col-12">
                                        <h6>Region</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{user.data.region}}</div>

                                    <div class="col-12">
                                        <h6>Phone</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{user.data.phone}}</div>

                                    <div class="col-12">
                                        <h6>Account Balance</h6>
                                    </div>
                                    <div class="col-12 mb-3">${{user.data.balance}}</div>

                                    <div class="col-12">
                                        <h6>User Wallets</h6>
                                    </div>

                                    <template v-if="user.data.wallets">
                                        <div v-for="(val, key, idx) in user.data.wallets" :key="'user_wal_'+idx">
                                            <div class="col-12">
                                                <h6 class="text-primary">{{key}}</h6>
                                            </div>
                                            <ol class="col-12" v-for="(wallet, idx) in val" :key="'wall_'+idx">
                                                <li>{{wallet}}</li>
                                            </ol>
                                        </div>
                                    </template>

                                    <div class="col-12">
                                        <h6>Created at</h6>
                                    </div>
                                    <div class="col-12">{{getReadableDate(user.data.createdAt)}}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Close</button>
<!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "ViewUser",
    props: ['user'],
    mixins: [methodsMixin]
}
</script>

<style scoped>

</style>