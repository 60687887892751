<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="min-height: 400px">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Balance</th>
                                        <th>Role</th>
                                        <th>Created</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="users.length > 0">
                                        <tr v-for="(user, idx) in users" :key="idx">
                                            <td>
                                                <strong>{{ idx+1 }}</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span class="w-space-no">{{ user.data.name }}</span>
                                                </div>
                                            </td>
                                            <td>{{user.data.email}}	</td>
                                            <td>${{user.data.balance}}</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <template v-if="user.data.is_admin">
                                                        <i class="fa fa-circle text-primary mr-1"></i>
                                                        admin
                                                    </template>
                                                    <template v-else>
                                                        <i class="fa fa-circle text-info mr-1"></i>
                                                        user
                                                    </template>
                                                </div>
                                            </td>
                                            <td>{{getReadableDate(user.data.createdAt)}}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <a @click="active_user=user" href="javascript:void(0)" class="btn btn-primary shadow btn-xs sharp mr-1" data-toggle="modal" data-target="#viewUser">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
<!--                                                    <a href="javascript:void(0)" class="btn btn-danger shadow btn-xs sharp"><i class="fa fa-trash"></i></a>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="7">
                                                <div class="col-12 text-center mt-4">
                                                    <h6 class="text-info">Empty users list</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <view-user :user="active_user"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../../utils/methodsMixin";
import ViewUser from "./ViewUser";

export default {
    name: "Users",
    data(){
      return {
          active_user: {id: '', data: {}}
      }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('user', {
            users: 'getAllUsers'
        })
    },
    components: {
        ViewUser,
    },
    mounted() {
        this.$store.dispatch('user/fetchAllUsers')
    }
}
</script>

<style scoped>

</style>